import React from "react";
import styled from "styled-components";

import FeaturedWork from "@components/home/featuredWork";
import { Container, H2, P } from "@util/standard";
import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT } from "@util/constants";

const FormContainer = styled(Container)`
  flex-direction: column;
  width: 70%;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 90%;
  }
`;

export default function ThankYou() {
  return (
    <Container autoWidthNoMargin flexDirection="column">
      <FormContainer>
        <H2>Thank you for getting in touch!</H2>
        <P>
          We’ll be in touch as soon as possible. In the meantime, feel free to
          scroll down and check out some previous work.
        </P>
      </FormContainer>
      <FeaturedWork />
    </Container>
  );
}
